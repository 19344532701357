import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

interface Props {
  siteTitle: string;
}

const Header: React.FC<Props> = ({ siteTitle }) => (
  <header>
    <div id="header-wrapper">
      <Link to="/">
        <div id="header-logo">
          <StaticImage
            src="../images/1step_logo_w_t.png"
            height={50}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="1STEP Logo"
            style={{ margin: 'auto', padding: 0, marginRight: '0.5rem' }}
            />
        </div>
      </Link>
    </div>
  </header>
)

export default Header

